import React from "react"
import debounce from "lodash/debounce"
import classNames from "classnames"
import PostCard from "../PostCard/PostCard"
// import { get } from '../../utilities/get.js';
import { UserDataContext } from "../../context/UserDataContext"
import "./main.scss"
import { get } from "../../utilities/get"

const Main = () => {
  const [userData] = React.useContext(UserDataContext)
  //
  const [isMessageReveled, setMessageReveled] = React.useState(false)
  // TODO: Add logic reporting full scroll event
  // const [isBottomReached, setBottomReached] = React.useState(false)
  const message = React.useRef()

  // TODO: This works, if the ID is not dynamic. So maybe it need to be async with a loader in the future.
  // TODO: We should also abstract the first segment f the URL so it work locally and on the remote server with editing.
  /*  React.useEffect(() => {
    // get data from GitHub api
    // fetch(`https://api.github.com/repos/gatsbyjs/gatsby`)
    fetch(`http://christmas-2001.gd:8888/static/log/FirstName.php?u=${userData.id}`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        // setStarsCount(resultData.stargazers_count)
        console.log(resultData)
      }) // set data for the number of stars
  }, []) */

  React.useLayoutEffect(() => {
    const calcMessageReveal = () => {
      const { pageYOffset } = window
      const pageInnerHeight = window.innerHeight
      const messageYOffset = message.current.getBoundingClientRect().top
      // console.log('window.pageYOffset: ' + window.pageYOffset)
      // console.log('window.innerHeight: ' + window.innerHeight)

      if (messageYOffset < pageYOffset + pageInnerHeight) {
        setMessageReveled(true)
      } else {
        setMessageReveled(false)
      }
    }

    const scrollEventDebouncer = debounce(() => {
      calcMessageReveal()
    }, 100)

    if (typeof window !== "undefined") {
      window.addEventListener("load", calcMessageReveal())
      window.addEventListener("scroll", scrollEventDebouncer)
      window.addEventListener("resize", scrollEventDebouncer)
    }
    // Remove listener (like componentWillUnmount)
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("load", calcMessageReveal())
        window.removeEventListener("scroll", scrollEventDebouncer)
        window.removeEventListener("resize", scrollEventDebouncer)
      }
    }
  }, [])

  const cookieClick = () => {
    get(`static/log/LogEvent.php?u=${userData.id}&e=cookie clicked`)
  }
  return (
    <main className="Main">
      <div className="Main-postCard">
        <div className="Main-postCardInner">
          <PostCard />
        </div>
      </div>
      <div className="Main-message">
        <div
          ref={message}
          className={classNames("Main-messageInner", {
            "is-visible": isMessageReveled === true,
          })}
        >
          <p>Greetings 👋</p>

          <p>
            The best part about writing these holiday greetings is taking the
            time to reflect on life, and how much we have to be grateful for.
          </p>
          <p>
            After wrapping up their year of remote learning in the spring, Cora
            and Lily resumed a brick and mortar education this fall 🚌. Here in
            Hopkinton, the 2nd and 4th graders go to different schools. Maybe
            that&apos;s a good thing after spending every waking hour together
            last year. The transition has been smooth, as they won the lottery
            with awesome teachers and classmates. The kids love getting out of
            the house, and it has made the work-day a little bit easier for the
            adults.
          </p>
          <p>
            For Jenna that meant a return to Bose 🎧, where she&apos;s doing
            visual design work for their in-house incubator team. All the while,
            breaking in her remodeled, at-home office space. Like this card,
            better late than never I guess. Meanwhile I&apos;m looking forward
            to new design opportunities, having decided to move on from Liberty
            Mutual after seven great years. Things are already looking busy for
            2022 which is exciting. 🚀
          </p>
          <p>
            I should also add it&apos;s been fun to get back to some
            extracurricular activities. Cora has bridged from the Brownies to
            become a Junior Girl Scout.{" "}
            <a
              href="https://tinyurl.com/2v9d66n6"
              target="_blank"
              rel="noreferrer"
              onClick={() => cookieClick()}
            >
              Click here
            </a>{" "}
            if you&apos;re interested in cookies again this year 🍪. Lily has
            been exploring a newfound passion for athletics with soccer ⚽️ last
            fall and basketball this winter, which has been an opportunity for
            me to chip in as a coach. Not to mention the return of my volleyball
            league for the first time since 2019! 🏐
          </p>
          <p>
            It&apos;s been a great year. We&apos;ve taken our jabs 🥊, stayed
            healthy, and are looking forward to new challenges in 2022. We wish
            you and yours a wonderful holiday season and best of luck for the
            new year.
          </p>
          <p>- John, Jenna, Cora, Lily and Finn 🐾</p>
        </div>
      </div>
    </main>
  )
}

export default Main
